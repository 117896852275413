.services{
padding: 40px 0 40px 0;
}
.s-serv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.s-serv>:nth-child(1){
    color: white;
    font-weight: bold;
    font-size: 45px;
}
.s-serv>:nth-child(2){
    color: white;
    font-size: 15px;
    display: flex;
    text-align: center;
   

}

.s-service{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-top: 35px;
}
.s-container{
    background-color: white;
    width: 175px;
    height: 230px;
    display: flex;
    flex-direction: column;
    gap: 3.5px;
    border-radius: 14px;
    padding-left: 15px;
}
.s-image1{
    display: flex;
    align-items: center;
    width: 50px;
    height: 60px;
    margin-top: 20px;
    margin-bottom: 20px;

}
.s-container>:nth-child(2){
    font-size: 16px;
    font-weight: bold;

}
.s-container>:nth-child(3){
    font-size: 12px;
}
.s-image2{
    display: flex;
    align-items: center;
    width: 80px;
    height: 90px;
    margin-top: 20px;
    margin-bottom: 20px;

}
.s-image3{
    display: flex;
    align-items: center;
    width: 50px;
    height: 72px;
    margin-top: 20px;
    margin-bottom: 20px;

}
.s-image4{
    display: flex;
    align-items: center;
    width: 62px;
    height: 55px;
    margin-top: 20px;
    margin-bottom: 20px;

}














.services {
    padding: 40px 0;
}

.s-serv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.s-serv>:nth-child(1) {
    color: white;
    font-weight: bold;
    font-size: 45px;
}

.s-serv>:nth-child(2) {
    color: white;
    font-size: 15px;
    display: flex;
    text-align: center;
}

.s-service {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-top: 35px;
}

.s-container {
    background-color: white;
    width: 175px;
    height: 220px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 14px;
    padding-left: 15px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.s-image1,
.s-image2,
.s-image3,
.s-image4 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 25px;
}

.s-image1 {
    width: 50px;
    height: 60px;
}

.s-image2 {
    width: 70px;
    height: 100px;
    margin-bottom: -10px;
}

.s-image3 {
    width: 45px;
    height: 72px;

}

.s-image4 {
    width: 62px;
    height: 55px;
}

.s-container>:nth-child(2) {
    font-size: 18px;
    font-weight: bold;
}

.s-container>:nth-child(3) {
    font-size: 13px;
}

/* Responsive Design */

@media (max-width: 1024px) {
    .s-service {
        gap: 30px;
    }

    .s-container {
        width: 150px;
        height: 190px;
    }
    
    .s-image1,
    .s-image2,
    .s-image3,
    .s-image4 {
        margin-bottom: 20px;
    }
    
    .s-container>:nth-child(2) {
        font-size: 16px;
    }

    .s-container>:nth-child(3) {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .s-service {
        flex-direction: column;
        gap: 20px;
        margin-top: 25px;
    }

    .s-container {
        width: 130px;
        height: 170px;
    }

    .s-image1,
    .s-image2,
    .s-image3,
    .s-image4 {
        margin-bottom: 15px;
    }

    .s-container>:nth-child(2) {
        font-size: 15px;
    }

    .s-container>:nth-child(3) {
        font-size: 11px;
    }
}

@media (max-width: 480px) {
    .s-serv>:nth-child(1) {
        font-size: 28px;
    }

    .s-serv>:nth-child(2) {
        font-size: 13px;
    }

    .s-service {
        gap: 15px;
        margin-top: 20px;
    }

    .s-container {
        width: 120px;
        height: 150px;
    }

    .s-image1,
    .s-image2,
    .s-image3,
    .s-image4 {
        width: 40px;
        height: auto;
    }

    .s-container>:nth-child(2) {
        font-size: 14px;
    }

    .s-container>:nth-child(3) {
        font-size: 10px;
    }

    .s-container{
        background-color: white;
        width: 175px;
        height: 230px;
        display: flex;
       
    }
}
