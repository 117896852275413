.t-testimonials {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 30px; 
    margin-top: 40px;

}

.t-testimonials>:nth-child(1) {
    color: white;
    font-weight: bold;
    font-size: 45px;
}

.t-testimonials>:nth-child(2) {
    color: white;
    font-size: 15px;
    display: flex;
    text-align: center;
}

.carousel-container {
    width: 50%; /* Increased width slightly to balance space */
    margin: 40px auto; /* Added margin on top and bottom */
    padding: 15px 15px; /* Adjusted padding to create space inside the container */
    border-radius: 15px; /* Softened the border-radius */
    height: auto; /* Let the height adjust dynamically based on content */
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Added box-shadow for a cool effect */
    background-color: #ffffff; /* A light background for better contrast */
}

.carousel-item {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensure space between image and text */
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 10px;
}

.carousel-content {
    display: flex;
    flex-direction: row; /* Display content horizontally */
    align-items: center;
}

.carousel-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px; /* Adds space between the image and the text */
}

.carousel-text {
    text-align: left; /* Aligns text left to match the horizontal layout */
}

.quote {
    font-style: italic;
    margin-bottom: 10px;
}

.name {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px;
    color: #0e11ce;
}

.post {
    font-size: 16px;
    color: #666;
}












.t-testimonials {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 30px; 
    margin-top: 40px;
}

.t-testimonials>:nth-child(1) {
    color: white;
    font-weight: bold;
    font-size: 45px;
}

.t-testimonials>:nth-child(2) {
    color: white;
    font-size: 15px;
    display: flex;
    text-align: center;
}

/* Carousel Container */
.carousel-container {
    width: 50%;
    margin: 40px auto;
    padding: 15px;
    border-radius: 15px;
    height: auto;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.carousel-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    background-color: #f9f9f9;
    border-radius: 10px;
}

.carousel-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.carousel-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 20px;
}

.carousel-text {
    text-align: left;
}

.quote {
    font-style: italic;
    margin-bottom: 10px;
}

.name {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px;
    color: #0e11ce;
}

.post {
    font-size: 16px;
    color: #666;
}

/* Responsive Design */
@media (max-width: 1024px) {
    /* Adjust carousel container for tablets */
    .carousel-container {
        width: 70%;
        padding: 20px;
    }

    .carousel-image {
        width: 130px;
        height: 130px;
    }

    .name {
        font-size: 16px;
    }

    .post {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    /* Adjust carousel container for mobile devices */
    .carousel-container {
        width: 90%;
        padding: 10px;
    }

    .carousel-item {
        flex-direction: column;
        text-align: center;
    }

    .carousel-content {
        flex-direction: column;
        align-items: center;
    }

    .carousel-image {
        margin-right: 0;
        margin-bottom: 20px;
        width: 120px;
        height: 120px;
    }

    .carousel-text {
        text-align: center;
    }

    .quote {
        font-size: 14px;
    }

    .name {
        font-size: 16px;
    }

    .post {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    /* Adjust further for smaller mobile screens */
    .t-testimonials>:nth-child(1) {
        font-size: 28px;
    }

    .t-testimonials>:nth-child(2) {
        font-size: 12px;
    }

    .carousel-container {
        width: 95%;
    }

    .carousel-image {
        width: 100px;
        height: 100px;
    }

    .quote {
        font-size: 12px;
    }

    .name {
        font-size: 14px;
    }

    .post {
        font-size: 12px;
    }
    .carousel-container{
        margin: 0 2px 0 -2px;
    }
    .carousel-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: auto;
    }
}
