.f-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 30px; 
    margin-top: 50px;

}

.f-footer>:nth-child(1) {
    color: white;
    font-weight: bold;
    font-size: 45px;
}
.f-footer>:nth-child(2) {
    color: white;
    font-size: 15px;
    display: flex;
    text-align: center;
}
.e-footer{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin: 15px 0 15px 0;
}
.i-footer{
    width: 450px;
    height: 55px;
    border-radius: 14px;
    background-color: #F8F8F8;
}
input{
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    outline-color: none;
    outline: none;
    padding-left: 8px;
}
button{
    width: 170px;
    height: 55px;
    color: white;
    background-color: #0e11ce;
    border: none;
    border-radius: 15px;
}
::placeholder{
    font-size: 10px;
    color: #666;
    padding-left: 10px
}








.f-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 50px;
}

.f-footer>:nth-child(1) {
    color: white;
    font-weight: bold;
    font-size: 45px;
}

.f-footer>:nth-child(2) {
    color: white;
    font-size: 15px;
    display: flex;
    text-align: center;
}

/* Email Subscription Footer Section */
.e-footer {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
}

.i-footer {
    width: 450px;
    height: 55px;
    border-radius: 14px;
    background-color: #F8F8F8;
}

input {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    padding-left: 8px;
}

button {
    width: 170px;
    height: 55px;
    color: white;
    background-color: #0e11ce;
    border: none;
    border-radius: 15px;
    cursor: pointer;
}

button:hover {
    background-color: #1e98df; /* Darken button on hover */
    transition: 0.3s ease;
}

::placeholder {
    font-size: 10px;
    color: #666;
    padding-left: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
    /* Footer Title Text */
    .f-footer>:nth-child(1) {
        font-size: 35px;
    }

    .f-footer>:nth-child(2) {
        font-size: 14px;
    }

    /* Email Input and Button */
    .e-footer {
        flex-direction: column;
        gap: 20px;
    }

    .i-footer {
        width: 90%; /* Adjust width for mobile */
        height: 50px;
    }

    button {
        width: 90%; /* Adjust width for mobile */
        height: 50px;
    }

    input {
        padding-left: 10px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .f-footer>:nth-child(1) {
        font-size: 28px;
    }

    .f-footer>:nth-child(2) {
        font-size: 12px;
    }

    .i-footer {
        width: 100%;
        height: 45px;
    }

    button {
        width: 100%;
        height: 45px;
    }

    input {
        font-size: 12px;
    }

    ::placeholder {
        font-size: 9px;
    }
}
