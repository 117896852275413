.about {
    display: flex;
    flex-direction: column; /* Change to column for mobile responsiveness */
    justify-content: center;
    align-items: center; /* Center align content */
    padding: 20px 0; 
    gap: 30px; /* Maintain gap for spacing */
}

.a-img {
    width: 100%; /* Make image responsive */
    height: 500px; /* Maintain aspect ratio */
    max-width: 500px; /* Limit maximum width for larger screens */
    border-radius: 8rem;
    margin-top: 8rem;
}

.a-right {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center align text on smaller screens */
}

.a-right > :nth-child(1) {
    color: white;
    font-size: 2.5rem; /* Responsive font size */
    font-weight: bold;
    text-align: center; /* Center align text */
    margin-bottom: 10px; /* Add space below the title */
}

.a-right > :nth-child(2) {
    color: white;
    font-size: 1rem; /* Responsive font size */
    text-align: center; /* Center align text */
}

/* Slider Container */
.slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Full width */
    max-width: 350px; /* Limit width on larger screens */
    margin: 20px auto;
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.slider-title {
    font-size: 24px;
    margin-bottom: 5px;
    color: #333;
}

/* Slider Styles */
.category-slider {
    width: 100%; /* Full width */
    margin-bottom: 0; /* Adjust margin */
}

.category-slider h4 {
    margin-bottom: 5px;
    color: #666;
}

/* Slider */
.slider {
    width: 100%; /* Full width */
    -webkit-appearance: none;
    appearance: none;
    height: 8px;
    background-color: #0e11ce;
    border-radius: 5px;
    outline: none;
    opacity: 0.8;
    transition: opacity 0.2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: #ddd;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background-color: #0e11ce;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.slider-value {
    margin-top: 1px;
    font-size: 1rem; /* Responsive font size */
    color: #333;
    text-align: right;
}

/* Media Queries for Responsiveness */
@media (min-width: 768px) {
    .about {
        flex-direction: row; /* Switch back to row on larger screens */
        justify-content: center; /* Center the content */
        gap: 30px; /* Maintain gap */
    }

    .a-right {
        align-items: center; /* Center align items */
    }

    .a-img {
        max-width: 500px; /* Limit the width on larger screens */
    }
}

/* Tablet responsiveness */
@media (max-width: 1024px) {
    .about {
        gap: 30px; /* Slightly reduce gap for better fit */
    }

    .a-img {
        width: 400px; /* Slightly smaller image on tablet */
        height: auto; /* Maintain proportions */
       
    }

    .a-right > :nth-child(1) {
        font-size: 35px; /* Reduce font size for better view */
    }

    .a-right > :nth-child(2) {
        font-size: 14px; /* Slight reduction for better readability */
    }
}

/* Mobile responsiveness for Android/phones */
@media (max-width: 768px) {
    .about {
        flex-direction: column; /* Stack vertically for smaller screens */
        gap: 20px; /* Reduce gap between image and text */
        padding: 10px; /* Less padding for smaller devices */
    }

    .a-img {
        width: 300px; /* Reduce image width for phones */
        height: auto;
        border-radius: 6rem;
    }

    .a-right > :nth-child(1) {
        font-size: 28px; /* Reduce header size for mobile */
    }

    .a-right > :nth-child(2) {
        font-size: 12px; /* Smaller font for mobile */
    }
}

/* Further adjustment for very small screens like small Android devices */
@media (max-width: 480px) {
    .about {
        padding: 5px 15px;
    }

    .a-img {
        width: 100%; /* Make image take full width */
        height: auto;
        border-radius: 2rem;
        
    }

    .a-right > :nth-child(1) {
        font-size: 24px; /* Smaller header for very small screens */
    }

    .a-right > :nth-child(2) {
        font-size: 12px; /* Keep the text readable */
    }
}
