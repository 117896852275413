.navbar{
    display: flex;
    justify-content: center;
    align-self: center;
    align-items: center;
    padding: 15px 10px 10px 15px;
    gap: 20px;
    padding-top: 20px;

}
.n-logo{
    display: flex;
    gap: 1px;
    padding-right: 24px;
   

}
ul li a
{
    align-items: center;
    display: flex;
    padding-top: 10px;
}
ul{
    display: flex;
    justify-content: row;
    gap: 2rem;
   

}
li{
    list-style: none;
}
li a{
    color: white;
    font-size: 1rem;
    text-decoration: none;
    margin-top: 30px;
    padding-right: 10px;
}

.n-name{
        color: whitesmoke;
        cursor: pointer;
        text-transform: capitalize;
        font-size: 30px;
        font-weight: bold;
        padding-left: 5px;
        padding-top: 5px;


}
.n-button{
    width: 128px;
    height: 32px;
    justify-content: center;
    align-items: center;
    background-color: #0e11ce;
    border: none;
    border-radius: 7px;
    color: white;
}
.n-img{
    width: 50px;
    height: 46px;
}







/* General navbar styles */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #333;
    color: white;
  }
  
  .n-logo {
    display: flex;
    align-items: center;
  }
  
  .n-img {
    width: 50px;
    height: auto;
  }
  
  .n-name {
    margin-left: 10px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .nav-links {
    display: flex;
    list-style: none;
  }
  
  .nav-links li {
    margin: 0 15px;
  }
  
  .nav-links li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
  }
  
  .n-button {
    padding: 10px 20px;
    background-color: #0e11ce;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  /* Hamburger styles */
  .hamburger {
    display: none;
    cursor: pointer;
    flex-direction: column;
    gap: 5px;
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    transition: all 0.3s ease;
  }
  
  .hamburger.open .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }
  
  .hamburger.open .bar:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger.open .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
  
  /* Mobile menu (hidden by default) */
  .nav-links {
    transition: all 0.3s ease;
  }
  
  .nav-links.open {
    display: block;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #333;
    text-align: center;
  }
  
  .nav-links.open li {
    margin: 20px 0;
  }
  
  .nav-links li a:hover {
    color: #0e11ce;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .nav-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      background-color: #333;
      z-index: 100;
    }
  
    .hamburger {
      display: flex;
    }
    .n-button{
        width: 105px;
        height: 32px;
        font-size: 10px;
        padding: 5px 20px;
       
    }
  }

  









  /* General navbar styles */
.navbar {
  display: flex;
  justify-content: space-between; /* Spreads the logo and links across */
  align-items: center; /* Vertically centers items */
  padding: 20px 50px; /* Adds padding around the navbar */
  background-color: #333;
  color: white;
  position: relative;
  z-index: 100;
}

.n-logo {
  display: flex;
  align-items: center; /* Aligns logo image and text vertically */
}

.n-img {
  width: 50px;
  height: auto;
}

.n-name {
  margin-left: 10px;
  font-size: 24px;
  font-weight: bold;
  color: white;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin: 0 15px;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.n-button {
  padding: 10px 20px;
  background-color: #0e11ce;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

/* Hamburger styles for mobile */
.hamburger {
  display: none; /* Hidden on desktop */
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease;
}

/* Mobile menu (hidden by default) */
.nav-links {
  transition: all 0.3s ease;
}

/* Responsive styles */
@media (max-width: 768px) {
  .nav-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 10px;
      left: 0;
      width: 100%;
      background-color: #333;
      z-index: 100;
  }

  .nav-links.open {
      display: block;
  }

  .nav-links li {
      margin: 20px 0;
      text-align: center;
  }
  .navbar{
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .hamburger {
      display: flex; /* Shows hamburger icon on mobile */
  }

  .n-button {
      width: 100px;
      height: 32px;
      font-size: 12px;
      padding: 5px 10px;
  }
}
