.hero{
     justify-content: space-between; 
    display: flex;
    flex: 1 1;
    gap: 168px;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 50px 190px 30px 180px;

    
}
.h-left{
    display: flex;
    flex-direction: column;
   align-items: flex-start;

 
}
.h-left>:nth-child(1){
    font-size: 14px;
    color: white;
    
}
.h-left>:nth-child(2){
    font-size: 16px;
    color: #0e11ce;
}
.h-left>:nth-child(3){
    color: white;
    font-size: 4.5rem;
}
.h-left>:nth-child(4){
    color: white;
    font-size: 4rem;
    padding-left: 150px;
}
.h-left>:nth-child(5){
    color: white;
    font-size: 15px;

}
.h-hire{
    width: 90px;
    height: 25px;
    background-color: #0e11ce;
    color: white;
    justify-content: center;
    align-self: center;
    border: none;
    border-radius: 5px;
    display: flex;
    align-self: flex-start;
    align-items: center;
    margin: 15px 0 15px 0;

}






.h-right{
    flex: 1 1;
    display: flex;
   
}
.i-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.r-img{
    width: 350px;
    height: 400px;
}
.s-icon{
    display: flex;
    gap: 1rem;
}
.icon{
  width: 25px;
  height: 25px;
  padding-top: 10px;
}








.hero {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    padding: 50px 20px; /* Adjusted for better responsiveness */
    gap: 50px; /* Reduced gap for smaller screens */
    padding: 50px 190px 30px 180px;
}

.h-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.h-left > :nth-child(1) {
    font-size: 16px;
    color: white;
}

.h-left > :nth-child(2) {
    font-size: 20px;
    font-weight: bold;
    color: #0e11ce;
}

.h-left > :nth-child(3) {
    color: white;
    font-size: 4.5rem; /* Adjust this for smaller screens */
}

.h-left > :nth-child(4) {
    color: white;
    font-size: 4rem; /* Adjust this for smaller screens */
    padding-left: 20px; /* Reduced padding for responsiveness */
}

.h-left > :nth-child(5) {
    color: white;
    font-size: 15px; /* Adjust this for smaller screens */
}

.h-hire {
    width: 90px;
    height: 25px;
    background-color: #0e11ce;
    color: white;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
}

.h-right {
    flex: 1 1;
    display: flex;
    justify-content: center; /* Centered on larger screens */
}

.i-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.r-img {
    /* width: 350px; */
    height: 350px; /* This can be adjusted for responsiveness*/
    border-radius: 50%;
}

.s-icon {
    display: flex;
    gap: 1rem;
}

.icon {
    width: 25px;
    height: 25px;
    padding-top: 10px;
}

/* Responsive Design */

@media (max-width: 1024px) {
    .hero {
        flex-direction: column; /* Stack items on medium screens */
        padding: 30px 10px; /* Reduced padding */
        gap: 20px; /* Reduced gap */
    }

    .h-left > :nth-child(3) {
        font-size: 3.5rem; /* Adjusted font size */
    }

    .h-left > :nth-child(4) {
        font-size: 3rem; /* Adjusted font size */
        padding-left: 0; /* Removed padding */
    }

    .r-img {
        width: 300px; /* Adjusted image size */
        height: 350px; /* Adjusted image size */
    }
}

@media (max-width: 768px) {
    .hero {
        padding: 20px; /* Further reduce padding */
    }
    .h-left > :nth-child(2) {
        font-size: 20px;
        font-weight: bold;
        color: #0e11ce;
    }
    

    .h-left > :nth-child(3) {
        font-size: 2.5rem; /* Further reduced font size */
    }

    .h-left > :nth-child(4) {
        font-size: 2.5rem; /* Further reduced font size */
    }

    .h-hire {
        width: 80px; /* Adjust button size */
        height: 20px; /* Adjust button size */
        font-size: 12px; /* Adjust font size */
    }

    .r-img {
        width: 280px; /* Further adjust image size */
        height: 340px; /* Further adjust image size */
        border-radius: 10%;
    }
}

@media (max-width: 480px) {
    .h-left > :nth-child(3) {
        font-size: 2rem; /* Further reduced font size */
    }

    .h-left > :nth-child(4) {
        font-size: 2rem; /* Further reduced font size */
    }

    .h-hire {
        width: 70px; /* Further adjust button size */
        height: 20px; /* Further adjust button size */
        font-size: 10px; /* Further adjust font size */
    }

    .r-img {
        width: 200px; /* Further adjust image size */
        height: 250px; /* Further adjust image size */
    }
}



/*  Slide in  */




/* Add keyframes for sliding animations */
@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInRight {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Apply animations to the hero text */
.h-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    animation: slideInLeft 1s ease-out; /* Text slides in from the left */
}

/* Apply animation to the hero image */
.h-right {
    flex: 1 1;
    display: flex;
    justify-content: center;
    animation: slideInRight 1s ease-out; /* Image slides in from the right */
}

/* Existing hero styles */
.hero {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
    gap: 50px;
    padding: 50px 190px 30px 180px;
}

/* Responsive Design and other styles remain the same */
