.lastfooter{
display: flex;
flex-direction: column;
}
.img-last{
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin: 50px 0 40px 0;
}
.img-last>:nth-child(1){
    width: 40px;
    height: 36px;
}
.img-last>:nth-child(2){
    color: wheat;
}
ul{
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
    margin: 0 0 40px 0;
}
li{
    list-style: none;
    color: white;

}
li:hover{
    transform: scale(1.0);
    color: #0e11ce;
}

.s-icon{
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

}
.icon{
  width: 25px;
  height: 25px;
  padding-top: 10px;
}
.b-lf{
    width: 100%;
    height: 60px;
    background-color: black;
    justify-content: center;
    color: white;
    align-items: center;
    display: flex;
    gap: 3px;
}
span{
    color: #0e11ce;
    font-size: 1rem;
    padding: 0 5px 0 5px;
}







.lastfooter {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #333;
    color: white;
  }
  
  /* Footer Logo and Title */
  .img-last {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin: 50px 0 40px 0;
  }
  
  .img-last>:nth-child(1) {
    width: 40px;
    height: 36px;
  }
  
  .img-last>:nth-child(2) {
    color: wheat;
    font-size: 18px;
  }
  
  /* Footer Navigation Links */
  ul {
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
    margin: 0 0 40px 0;
    padding: 0;
  }
  
  li {
    list-style: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  li:hover {
    transform: scale(1.1);
    color: #0e11ce;
    transition: 0.3s ease;
  }
  
  /* Social Icons */
  .s-icon {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .icon {
    width: 25px;
    height: 25px;
    padding-top: 10px;
    cursor: pointer;
  }
  
  .icon:hover {
    transform: scale(1.1);
    transition: 0.3s ease;
  }
  
  /* Bottom Footer */
  .b-lf {
    width: 100%;
    height: 60px;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    color: white;
  }
  
  .b-lf span {
    color: #0e11ce;
    font-size: 1rem;
    padding: 0 5px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .img-last {
      flex-direction: column;
      margin: 30px 0;
    }
  
    ul {
      flex-direction: column;
      gap: 10px;
      margin-bottom: 30px;
    }
  
    li {
      font-size: 14px;
    }
  
    .s-icon {
      gap: 0.8rem;
      margin-bottom: 20px;
    }
  
    .icon {
      width: 20px;
      height: 20px;
    }
  
    .b-lf {
      flex-direction: column;
      height: auto;
      padding: 10px 0;
      font-size: 0.9rem;
    }
  }
  