.project{
    padding: 40px 0 50px 0;


}
.p-project{
   display: flex;
   justify-content: center;
   align-items: center;
   align-self: center;
   flex-direction: column;
}
.p-project>:nth-child(1){
    color: white;
    font-weight: bold;
    font-size: 45px;
}
.p-project>:nth-child(2){
    color: white;
    font-size: 15px;
    display: flex;
    text-align: center;
   

}
.pm-project{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 30px 350px 30px 350px;
}
.pm-project>*{
    width: 100%;
    height: 100%;
    padding: 7px 5px 7px 5px;
    background-color: white;
    border-radius: 4px;
    border: none;
    display: flex;
    justify-content: center;
}
.pm-project>:nth-child(3){
    background-color: #0e11ce;
    color: white;
}
 .image-p{
    width: 210px;
    height: 230px;
    display: flex;
    align-items: center;
    padding: 7px 10px 5px 10px;
}
.p-contain{
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}
.ps-contain{
    width: 230px;
    height: 250px;
    border-radius: 10px;
     background-color: #FFEBDB; 
    display: flex;
    flex-direction: column;
    gap: 5px;


}
.ps-contain>:nth-child(2){
    font-size: 12px;
    font-weight: bold;
    color: #0e11ce;
    margin-top: 18px;
}
.ps-contain>:nth-child(3){
     font-size: 14px; 
    color:white;
}











.project {
    padding: 40px 0 50px 0;
}

.p-project {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.p-project>:nth-child(1) {
    color: white;
    font-weight: bold;
    font-size: 45px;
}

.p-project>:nth-child(2) {
    color: white;
    font-size: 15px;
    display: flex;
    text-align: center;
}

.pm-project {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 30px 350px;
}

.pm-project>* {
    width: 100%;
    height: 100%;
    padding: 7px 5px;
    background-color: white;
    border-radius: 4px;
    border: none;
    display: flex;
    justify-content: center;
}

.pm-project>:nth-child(3) {
    background-color: #0e11ce;
    color: white;
}

.image-p {
    width: 210px;
    height: 230px;
    display: flex;
    align-items: center;
    padding: 7px 10px;
}

.p-contain {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.ps-contain {
    width: 230px;
    height: 250px;
    border-radius: 10px;
    background-color: #FFEBDB;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.ps-contain>:nth-child(2) {
    font-size: 12px;
    font-weight: bold;
    color: #0e11ce;
    margin-top: 18px;
}

.ps-contain>:nth-child(3) {
    font-size: 14px;
    color: white;
}

/* Responsive Design */

@media (max-width: 1024px) {
    .pm-project {
        padding: 30px 100px; /* Reduce padding for medium screens */
    }

    .image-p {
        width: 180px;
        height: 200px; /* Slightly smaller image size for tablets */
    }

    .ps-contain {
        width: 200px;
        height: 230px;
    }

    .ps-contain>:nth-child(2) {
        font-size: 11px;
    }

    .ps-contain>:nth-child(3) {
        font-size: 13px;
    }
}

@media (max-width: 768px) {
    .pm-project {
        flex-direction: column;
        padding: 20px;
        gap: 20px;
    }

    .image-p {
        width: 160px;
        height: 180px; /* Further reduction for smaller screens */
    }

    .ps-contain {
        width: 180px;
        height: 210px;
    }

    .ps-contain>:nth-child(2) {
        font-size: 10px;
    }

    .ps-contain>:nth-child(3) {
        font-size: 12px;
    }

    .p-project>:nth-child(1) {
        font-size: 35px;
    }

    .p-project>:nth-child(2) {
        font-size: 13px;
    }
}

@media (max-width: 480px) {
    .pm-project {
        padding: 15px;
    }

    .image-p {
        width: 140px;
        height: 160px;
    }

    .ps-contain {
        width: 160px;
        height: 190px;
    }

    .ps-contain>:nth-child(2) {
        font-size: 9px;
    }

    .ps-contain>:nth-child(3) {
        font-size: 11px;
    }

    .p-project>:nth-child(1) {
        font-size: 28px;
    }

    .p-project>:nth-child(2) {
        font-size: 12px;
    }
    .p-contain{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
}
